module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-material-ui@4.0.2_@emotion+react@11.10.5_gatsby@5.12.9/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.2.0_gatsby@5.12.9/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HZD Ortsgruppe Hamburg","short_name":"HZD OG HH","start_url":"/","background_color":"#6b37bf","theme_color":"#6b37bf","display":"standalone","icon":"src/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"d51f13cf1f4a22b1f4f758bb622053ee"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.12.9_eslint-import-resolver-typescript@3.6.1_react-dom@18.2.0_react@18.2.0_typescript@4.9.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
